import { ReactNode } from 'react';
import { ThemeProvider } from '@/hooks/useTheme';
import { IconSpritesheet } from '@/components/icon/IconSpritesheet';
import Logo from '@img/Logo148.png';

interface Props {
  children: ReactNode;
  footerTheme?: 'dark' | 'light';
  title?: string;
}

export function ErrorPageLayout({
  children,
  footerTheme = 'light',
  title = "There's been a glitch"
}: Props) {
  return (
    <>
      <title>{title}</title>
      <div className="flex h-full flex-col">
        <ThemeProvider theme="dark">
          <div className="flex items-center bg-black px-8 py-2 h-20">
            <img
              className="h-14 w-14"
              src={Logo}
              alt="Compass with a needle pointing north with the word Nettwerk underneath it"
            />
          </div>
          <div className="relative flex h-full flex-1 flex-col overflow-hidden">
            <ThemeProvider theme={footerTheme}>
              <div className="flex flex-1 flex-col h-full bg-white">
                <div className="flex-grow flex flex-1 h-full flex-col">{children}</div>
              </div>
            </ThemeProvider>
          </div>
        </ThemeProvider>
        <IconSpritesheet />
      </div>
    </>
  );
}
